import React, { useState, useEffect} from 'react';
import BuoyChart from './buoyChart';
import { WeatherStyle } from "../style/weatherStyle"

const Bouy = (props) => {
   return (
    <div css={WeatherStyle}>
      <div id="WeatherContent">
     
      <div className="clear"></div>
      <div className="scrollable">
      <BuoyChart />
      </div>
      <div className="clear"></div>
    </div>
    </div>
  )
}

export default Bouy
