import React from "react";
import {Line} from "react-chartjs-2"
import {Chart as ChartJS} from 'chart.js/auto'

export function LineChartJS({ChartData}) {
    return <Line data={ChartData} options={{ maintainAspectRatio: false }} />
}

export function LineChartJS2({ChartData}) {
    return <Line data={ChartData} options={{ maintainAspectRatio: false, scales: {
        y: {
            display: true, position: 'left', beginAtZero: true, max: 360, ticks: {stepSize: 45, steps: 8}
            },
        yRight: { 
            display: true, position: 'right', beginAtZero: true, max: 360, ticks: {stepSize: 45, steps: 8, callback: function(value, index) {
                switch (index){
                    case 0:
                    case 8:
                        return "N"
                    case 1:
                        return "NE"
                    case 2:
                        return "E" 
                    case 3:
                        return "SE"
                    case 4:
                        return "S"
                    case 5:
                        return "SW" 
                    case 6:
                        return "W"
                    case 7:
                        return "NW" 
                                        
                }
            }}
            }
        } 
    }}/>
}