import React, { useState, useEffect } from 'react';

import {LineChartJS, LineChartJS2} from './LineChart';
import axios from 'axios'



const BuoyChart = () => {

  var moment = require('moment');

  const [gettingData, setGettingData] = useState(true)
  const [latestData, setLatestData] = useState("")
  const [recentData, setRecentData] = useState("")
  const [chartDataWind, setDataWind] = useState()
  const [chartDataWindDirection, setDataWindDirection] = useState()
  const [chartDataWaveHeight, setDataWaveHeight] = useState()
  const [chartDataWavePeriod, setDataWavePeriod] = useState()
  const [chartDataWaveDirection, setDataWaveDirection] = useState()
  const [chartMetTemp, setMetTemp] = useState()
  const [chartMetAtmos, setMetAtmos] = useState()
  

  useEffect(() => {
    const fetchData = async () => {
      try{
        const latestResult = await axios(process.env.GATSBY_BUOY_API)
        setLatestData(latestResult.data.latest[171])

        const recentResult = await axios(process.env.GATSBY_BUOYR_API)
        setRecentData(recentResult.data)

        setDataWind({
          labels: recentResult.data.timeseries[171].met.filter((Time) => moment(Time.datetime).format('mm') === "00").map((Time) => moment(Time.datetime).format('HH:mm')),
          datasets: [
          {
            label: "Wind Speed",
            data: recentResult.data.timeseries[171].met.filter((Time) => moment(Time.datetime).format('mm') === "00").map((Wind) => Wind.wind_speed),
            borderColor:"#00426a",
            backgroundColor:"#0074bb"
          },
          {
            label: "Gust Speed",
            data: recentResult.data.timeseries[171].met.filter((Time) => moment(Time.datetime).format('mm') === "00").map((Wind) => Wind.gust_speed),
            borderColor:"#ffa500",
            backgroundColor:"#ffdb99"
          }]
        })

        setDataWindDirection({
          labels: recentResult.data.timeseries[171].met.filter((Time) => moment(Time.datetime).format('mm') === "00").map((Time) => moment(Time.datetime).format('HH:mm')),
          datasets: [{
            label: "Wind Direction",
            data: recentResult.data.timeseries[171].met.filter((Time) => moment(Time.datetime).format('mm') === "00").map((Wind) => Wind.wind_direction),
            borderColor:"#00426a",
            backgroundColor:"#0074bb"
          }         
        ]
        })

        setDataWaveHeight({
          labels: recentResult.data.timeseries[171].waves.filter((Time) => moment(Time.datetime).format('mm') === "00").map((Time) => moment(Time.datetime).format('HH:mm')),
          datasets: [{
            label: "Wave Height Max",
            data: recentResult.data.timeseries[171].waves.filter((Time) => moment(Time.datetime).format('mm') === "00").map((Wave) => Wave.wave_height_max),
            borderColor:"#00426a",
            backgroundColor:"#0074bb"
          },
          {
            label: "Wave Height Sig",
            data: recentResult.data.timeseries[171].waves.filter((Time) => moment(Time.datetime).format('mm') === "00").map((Wave) => Wave.wave_height_sig),
            borderColor:"#ffa500",
            backgroundColor:"#ffdb99"
          }]
        })

        setDataWavePeriod({
          labels: recentResult.data.timeseries[171].waves.filter((Time) => moment(Time.datetime).format('mm') === "00").map((Time) => moment(Time.datetime).format('HH:mm')),
          datasets: [{
            label: "Wave Period",
            data: recentResult.data.timeseries[171].waves.filter((Time) => moment(Time.datetime).format('mm') === "00").map((Wave) => Wave.wave_period_peak),
            borderColor:"#00426a",
            backgroundColor:"#0074bb"
          }]
        })

        setDataWaveDirection({
          labels: recentResult.data.timeseries[171].waves.filter((Time) => moment(Time.datetime).format('mm') === "00").map((Time) => moment(Time.datetime).format('HH:mm')),
          datasets: [{
            label: "Wave Direction",
            data: recentResult.data.timeseries[171].waves.filter((Time) => moment(Time.datetime).format('mm') === "00").map((Wave) => Wave.wave_direction_peak),
            borderColor:"#00426a",
            backgroundColor:"#0074bb"
          }]
        })

        setMetTemp({
          labels: recentResult.data.timeseries[171].met.filter((Time) => moment(Time.datetime).format('mm') === "00").map((Time) => moment(Time.datetime).format('HH:mm')),
          datasets: [{
            label: "Temperature (Degrees Celcius)",
            data: recentResult.data.timeseries[171].met.filter((Time) => moment(Time.datetime).format('mm') === "00").map((Temp) => Temp.temperature),
            borderColor:"#00426a",
            backgroundColor:"#0074bb"
          }]
        })

        setMetAtmos({
          labels: recentResult.data.timeseries[171].met.filter((Time) => moment(Time.datetime).format('mm') === "00").map((Time) => moment(Time.datetime).format('HH:mm')),
          datasets: [{
            label: "Atmospheric Pressure (millibars)",
            data: recentResult.data.timeseries[171].met.filter((Time) => moment(Time.datetime).format('mm') === "00").map((Pressure) => Pressure.atmos_pressure),
            borderColor:"#00426a",
            backgroundColor:"#0074bb"
          }]
        })

        setGettingData(false)
      }catch(error){
        (console.log(error))
      }
    }
    fetchData()
  },[])






  const [showWindTable, setWindTable] = useState(false)
  const toggleWindTable = () => {
    setWindTable(!showWindTable)
  }

  const [showWaveTable, setWaveTable] = useState(false)
  const toggleWaveTable = () => {
    setWaveTable(!showWaveTable)
  }

  const [showAirTable, setAirTable] = useState(false)
  const toggleAirTable = () => {
    setAirTable(!showAirTable)
  }

  return (
    <>
      {gettingData ? (
        <p>Loading...</p>
      ) : (
        <>
          <div>
            <h2>Wind</h2>
            <table style={{width:"100%"}}>
              <tr>
                <th>Updated at:</th>
                <th>Wind Direction</th>
                <th>Wind Speed (Knots)</th>
                <th>Gust Speed (Knots)</th>
                <th>Gust Direction</th>
              </tr>
              <tr>
                <td>{moment(latestData.met.timestamp).format('HH:mm')}</td>
                <td>{latestData.met.wind_direction.value}</td>
                <td>{latestData.met.wind_speed.value}</td>
                <td>{latestData.met.gust_speed.value}</td>
                <td>{latestData.met.gust_direction.value}</td>
              </tr>
            </table>
          </div>
          <div style={{display: "flex", height:"300px"}}>
            <div style={{width:"50%"}}><LineChartJS ChartData={chartDataWind} /></div>
            <div style={{width:"50%"}}><LineChartJS2 ChartData={chartDataWindDirection} /></div>
          </div>

          { showWindTable ? (<>
            <button onClick={toggleWindTable}> Hide Data </button>
            <table>
            <tr>
              <th>Time Stamp</th>
              <th>Wind Speed</th>
              <th>Wind Direction</th>
              <th>Gust Speed</th>
              <th>Gust Direction</th>
              <th>Date time</th>
            </tr>
            {recentData.timeseries[171].met.map((record) => {
              return(
                <tr>
                <td>{record.timestamp}</td>
                <td>{record.wind_speed}</td>
                <td>{record.wind_direction}</td>
                <td>{record.gust_speed}</td>
                <td>{record.gust_direction}</td>
                <td>{record.datetime}</td>
                </tr>
              )
            })}
          </table>
            </>) : (<button onClick={toggleWindTable}> Show Data </button>) }
      </>)}

      
        <h2> Waves </h2>
        {gettingData ? (
        <p>Loading...</p>
        ) : (
        <>
        <div>
          <table style={{width:"100%"}}>
            <tr>
              <th>Updated at:</th>
              <th>Wave Height (Sig)</th>
              <th>Wave Height (Max)</th>
              <th>Wave Period</th>
              <th>Wave Direction</th>
            </tr>
            <tr>
              <td>{moment(latestData.waves.timestamp).format('HH:mm')}</td>
              <td>{latestData.waves.wave_height_sig.value}</td>
              <td>{latestData.waves.wave_height_max.value}</td>
              <td>{latestData.waves.wave_period_peak.value}</td>
              <td>{latestData.waves.wave_direction_peak.value}</td>
            </tr>
          </table>
      </div>
      <div style={{display: "flex", height:"300px"}}>
        <div style={{width:"100%"}}><LineChartJS ChartData={chartDataWaveHeight} /></div>
      </div>
      <div style={{display: "flex", height:"300px"}}>
        <div style={{width:"50%"}}><LineChartJS ChartData={chartDataWavePeriod} /></div>
        <div style={{width:"50%"}}><LineChartJS2 ChartData={chartDataWaveDirection} /></div>
      </div>

      { showWaveTable ? (<>
        <button onClick={toggleWaveTable}> Hide Data </button>
        <table>
        <tr>
          <th>Time Stamp</th>
          <th>Wave Height (Sig)</th>
          <th>Wave Height (Max)</th>
          <th>Wave Period</th>
          <th>Wave Direction</th>
          <th>Date time</th>
        </tr>
        {recentData.timeseries[171].waves.map((record) => {
          return(
            <tr>
            <td>{record.timestamp}</td>
            <td>{record.wave_height_sig}</td>
            <td>{record.wave_height_max}</td>
            <td>{record.wave_period_peak}</td>
            <td>{record.wave_direction_peak}</td>
            <td>{record.datetime}</td>
            </tr>
          )
        })}
      </table>
        </>) : (<button onClick={toggleWaveTable}> Show Data </button>) }

      </>)}

      <h2>Met</h2>

      {gettingData ? (
        <p>Loading...</p>
        ) : (
        <>

      <div style={{textAlign:"center"}}>
          <table style={{width:"100%"}}>
            <tr>
              <th>Updated at:</th>
              <th>Atmospheric Pressure</th>
              <th>Air Temperature</th>
              <th>Humidity</th>
              <th>Dewpoint</th>
            </tr>
            <tr>
              <td>{moment(latestData.met.timestamp).format('HH:mm')}</td>
              <td>{latestData.met.atmos_pressure.value}</td>
              <td>{latestData.met.temperature.value}</td>
              <td>{latestData.met.humidity.value}</td>
              <td>{latestData.met.dewpoint.value}</td>
            </tr>
          </table>
          <div style={{display: "flex", height:"300px"}}>
            <div style={{width:"50%"}}><LineChartJS ChartData={chartMetTemp} /></div>
            <div style={{width:"50%"}}><LineChartJS ChartData={chartMetAtmos} /></div>
          </div>
      </div>


      { showAirTable ? (<>
        <button onClick={toggleAirTable}> Hide Data </button>
        <table>
        <tr>
          <th>Time Stamp</th>
          <th>Atmospheric Pressure</th>
          <th>Air Temperature</th>
          <th>Humidity</th>
          <th>Dewpoint</th>
          <th>Date time</th>
        </tr>
        {recentData.timeseries[171].met.map((record) => {
          return(
            <tr>
            <td>{record.timestamp}</td>
            <td>{record.atmos_pressure}</td>
            <td>{record.temperature}</td>
            <td>{record.humidity}</td>
            <td>{record.dewpoint}</td>
            <td>{record.datetime}</td>
            </tr>
          )
        })}
      </table>
        </>) : (<button onClick={toggleAirTable}> Show Data </button>) }

        </>)}

    </>
  );
}

export default BuoyChart;
